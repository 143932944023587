export const formDistributorCatalog =(t, data, formik)=> [
    {
        type: "text",
        name: "code",
        label: "Code"
    },
    {
        type: "text",
        name: "name",
        label: "Name"
    },{
        type: "select",
        name: "country",
        label: "Country",
        options: data.Countries.map((country, i)=> ({
            id: country.id, value: country.id, label: country.name
        })),
        properties:{
            active: formik.values.id !== ""
        }
    },{
        type: "select",
        name: "isTesting",
        label: "Is testing",
        options: [
            {label: "true", value: 1},
            {label: "false", value: 0}
        ]
    }
];

export const FormApprovals = (data, formik) => [
    {
      type: "text",
      name: "email",
      label: "Email",
        properties: {
            active: formik.values.id !== "",
        },
    },
    {
      type: "select",
      name: "status",
      label: "Status",
      options: [
        { id: 0, value: 0, label: "Inactive" },
        { id: 1, value: 1, label: "Active" },
      ],
    },
    {
      type: "select",
      name: "country",
      label: "Country",
      options: data.Countries.map((country) => ({
        id: country.id,
        value: country.code,
        label: country.name,
      })),
      properties: {
        active: formik.values.id !== "",
      },
    },
    {
      type: "select",
      name: "user_type",
      label: "User Type",
      options: [
        { id: 0, value: "Surgeon", label: "Surgeon" },
        { id: 1, value: "Patient", label: "Patient" },
      ],
    },
    {
      type: "text",
      name: "description",
      label: "Description",
    },
  ];

export const formRebateProgramCatalog =(t, formik)=> [
    {
        type: "text",
        name: "codePrefix",
        label: "Influencer Code *",
        handleKeyUp: (event) => {
            const value = event.target.value.toUpperCase();
            formik.setFieldValue("codePrefix", value);
        },
    },
    {
        type: "text",
        name: "firstName",
        label: "Influencer First Name *"
    },
    {
        type: "text",
        name: "lastName",
        label: "Influencer Last Name *"
    },
    {
        type: "text",
        name: "instagram",
        label: "Instagram *"
    },
    {
        type: "text",
        name: "termsAndConditionsUrl",
        label: "Terms and Conditions *"
    },
    {
        type: "text",
        name: "note",
        label: "Quote"
    },
    {
        type: "date",
        name: "surgeryStartDate",
        label: "Surgery Start Date *"
    },
    {
        type: "date",
        name: "surgeryEndDate",
        label: "Surgery End Date *"
    },
    {
        type: "date",
        name: "programEndDate",
        label: "Program End Date *"
    },
    {
        type: "select",
        name: "active",
        label: "Active *",
        options: [
            { id: 1, value: 1, label: "Enable" },
            { id: 0, value: 0, label: "Disable" },
        ],
    },
    {
        type: "file",
        name: "documentUri",
        label: "Upload Image or Video",
        properties: {
        onChange: (event) => {
            const file = event.target.files[0];
            formik.setFieldValue("documentUri", file);
        }},
    },

];
